
import { Header, Footer } from '../components/layout'

function isBlank(str) {
    return !str || /^\s*$/.test(str)
}

export default {
    components: {
        Header,
        Footer,
    },
    data() {
        return {
            class: '',
        }
    },
    mounted() {
        // const vm = this
        this.$bus.$on('on-popup-open', (flag) => {
            if (flag) {
                // vm.class = 'modal-open'
                document.body.classList.add('modal-open')
            } else {
                // vm.class = ''
                document.body.classList.remove('modal-open')
            }
        })
    },
    destroyed() {
        this.$bus.$off('on-popup-open')
    },
    head() {
        const st = this.$store.state
        return {
            htmlAttrs: {
                lang: 'th',
            },
            title: !isBlank(st.seo.title) ? st.seo.title : 'Propdeal',
            link: [
                {
                    rel: 'icon',
                    type: 'image/png',
                    href: '/favicon.ico',
                },
                {
                    rel: 'canonical',
                    href: st.seo.url,
                },
            ],
            script: [
                {
                    type: 'text/javascript',
                    innerHTML: `
                            !function (w, d, t) {
                            w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie","holdConsent","revokeConsent","grantConsent"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(
                            var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var r="https://analytics.tiktok.com/i18n/pixel/events.js",o=n&&n.partner;ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=r,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};n=document.createElement("script")
                            ;n.type="text/javascript",n.async=!0,n.src=r+"?sdkid="+e+"&lib="+t;e=document.getElementsByTagName("script")[0];e.parentNode.insertBefore(n,e)};


                            ttq.load('CSI903RC77UC379FBF60');
                            ttq.page();
                            }(window, document, 'ttq');
                        `,
                },
                {
                    type: 'text/javascript',
                    innerHTML: `
                            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                            })(window,document,'script','dataLayer','GTM-TGKVHP2');
                        `,
                },
                {
                    type: 'text/javascript',
                    innerHTML: `
                            !function(f,b,e,v,n,t,s)
                            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                            n.queue=[];t=b.createElement(e);t.async=!0;
                            t.src=v;s=b.getElementsByTagName(e)[0];
                            s.parentNode.insertBefore(t,s)}(window, document,'script',
                            'https://connect.facebook.net/en_US/fbevents.js');
                            fbq('init', '3509220906051189');
                            fbq('track', 'PageView');
                        `,
                },
            ],
            __dangerouslyDisableSanitizers: ['script'],
            meta: [
                {
                    name: 'title',
                    content: !isBlank(st.seo.title) ? st.seo.title : 'Propdeal',
                },
                {
                    name: 'description',
                    content: !isBlank(st.seo.description)
                        ? st.seo.description
                        : 'Propdeal',
                },
                {
                    name: 'keywords',
                    content: !isBlank(st.seo.keywords)
                        ? st.seo.keywords
                        : 'Propdeal',
                },
                {
                    property: 'og:type',
                    content: `website`,
                    hid: 'og:type',
                },
                {
                    property: 'og:url',
                    content: `${st.seo.url}`,
                    hid: 'og:url',
                },
                {
                    property: 'og:title',
                    content: !isBlank(st.seo.og_title)
                        ? st.seo.og_title
                        : 'Propdeal',
                    hid: 'og:title',
                },
                {
                    property: 'og:description',
                    content: st.seo.og_description,
                    hid: 'og:description',
                },
                {
                    property: 'og:image',
                    content: st.seo.og_image,
                    hid: 'og:image',
                },
                {
                    property: 'twitter:card',
                    content: `summary_large_image`,
                    hid: 'twitter:card',
                },
                {
                    property: 'twitter:url',
                    content: `${st.seo.url}`,
                    hid: 'twitter:url',
                },
                {
                    property: 'twitter:title',
                    content: !isBlank(st.seo.twitter_title)
                        ? st.seo.twitter_title
                        : 'Propdeal',
                    hid: 'twitter:title',
                },
                {
                    property: 'twitter:description',
                    content: st.seo.twitter_description,
                    hid: 'twitter:description',
                },
                {
                    property: 'twitter:image',
                    content: st.seo.twitter_image,
                    hid: 'twitter:image',
                },
            ],
        }
    },
}
